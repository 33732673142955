"use client";

import { useEffect, useState } from "react";

import { cn } from "@/lib/utils";

export function FloatingCTA({
  children,
  triggerEndElementId,
  triggerStartElementId,
}: {
  children: React.ReactNode;
  triggerEndElementId: string;
  triggerStartElementId: string;
}) {
  const [showCTA, setShowCTA] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const startElement = document.getElementById(triggerStartElementId);
      const endElement = document.getElementById(triggerEndElementId);

      if (startElement && endElement) {
        const startRect = startElement.getBoundingClientRect();
        const endRect = endElement.getBoundingClientRect();

        // startElementが画面内に入ったらCTAを表示
        const showStart = startRect.top <= window.innerHeight && startRect.bottom >= 0;

        // endElementが画面内に入ったらCTAを非表示
        const hideEnd = endRect.top <= window.innerHeight;

        setShowCTA(showStart && !hideEnd);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg transition-all duration-500 ease-in-out lg:hidden",
        showCTA ? "translate-y-0 opacity-100" : "translate-y-full opacity-0",
      )}
    >
      {children}
    </div>
  );
}
